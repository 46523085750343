/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import {
	commonApplicationMimeTypes,
	commonAudioMimeTypes,
	commonImageMimeTypes,
	commonTextMimeTypes,
	commonVideoMimeTypes,
} from '@onehat/ui/src/Constants/MimeTypes.js';
import _ from 'lodash';

export default function InspectionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "inspections__date"
		                    },
		                    {
		                        "name": "inspections__checklist_id"
		                    },
		                    {
		                        "name": "inspections__is_failure",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "inspections__comments",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "inspections__equipment_id"
		                    },
		                    {
		                        "name": "inspections__user_id"
		                    },
		                    {
		                        "name": "inspections__reference",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "inspections__has_attachments"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
			{
				title: 'Photos',
				type: 'Attachments',
				accept: commonImageMimeTypes.join(','),
				confirmBeforeDelete: true,
			},
			{
				title: 'Files',
				type: 'Attachments',
				accept: [
					...commonApplicationMimeTypes,
					...commonAudioMimeTypes,
					...commonTextMimeTypes,
					...commonVideoMimeTypes,
				].join(','),
				confirmBeforeDelete: true,
				useFileMosaic: false,
			},
		    {
		        "title": "Responses",
		        "type": "ResponsesGridEditor",
		        "selectorId": "responses__inspection_id",
				h: 1200,
		    },
		    // {
		    //     "title": "Work Orders",
		    //     "type": "WorkOrdersGridEditor",
		    //     "selectorId": "work_orders__inspection_id"
		    // }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="InspectionsEditor"
				title="Inspections"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				showPdfBtns={true}
				{...props}
			/>;
}

